export default {
  ID: 'ID',
  DIN: 'DIN ${index}',
  RxNumber: 'RxNumber ${index}',
  RxDate: 'RxDate ${index}',
  QtyDispense: 'QtyDispense ${index}',
  QtyRemain: 'QtyRemain ${index}',
  requireddaysupply: 'requireddaysupply${index}',
  currentsupply: 'currentsupply${index}',
  requiredsupply: 'requiredsupply${index}',
  maxdaysupply: 'maxdaysupply${index}',
  Days: 'Days ${index}',
  'RX?': 'RX?${index}',
  'NHP?': 'NHP?${index}',
  'OTC?': 'OTC?${index}',
  onHold: 'onHold ${index}',
  MED: 'MED ${index}',
  interaction: 'interaction${index}',
  GenericName: 'GenericName ${index}',
  'MED STR': 'MED ${index} STR',
  FORM: 'FORM ${index}',
  SIG: 'SIG ${index}',
  INDICATION: 'INDICATION ${index}',
  'MED AM QTY': 'MED ${index} AM QTY',
  'MED LUNCH QTY': 'MED ${index} LUNCH QTY',
  'MED DINNER QTY': 'MED ${index} DINNER QTY',
  'MED BEDTIME QTY': 'MED ${index} BEDTIME QTY',
  'MED OTHER QTY': 'MED ${index} OTHER QTY',
  'ADHERENCE YES': 'ADHERENCE YES ${index}',
  'ADHERENCE NO': 'ADHERENCE NO ${index}',
  'PATIENT COMMENT': 'PATIENT COMMENT ${index}',
  'MED PHARMACIST NOTES': 'MED ${index} PHARMACIST NOTES',
  'COMMENTS FOR MEDSCHECK RECORD': 'COMMENTS FOR MEDSCHECK RECORD ${index}',
  'RX ID': 'RX ID ${index}',
  'PARENT RX NUMBER': 'PARENT RX NUMBER ${index}',
  SIG: 'SIG ${index}',
  'PATIENT FIRST NAME': 'PATIENT FIRST NAME {$index}',
  'PATIENT LAST NAME': 'PATIENT LAST NAME ${index}',
  'BRAND NAME': 'BRAND NAME ${index}',
  'GENERIC NAME': 'GENERIC NAME ${index}',
  STRENGTH: 'STRENGTH ${index}',
  'PATIENT ID': 'PATIENT ID ${index}',
  'DOCTOR LAST NAME': 'DOCTOR LAST NAME ${index}',
  'DOCTOR FIRST NAME': 'DOCTOR FIRST NAME ${index}',
  DAYS: 'DAYS ${index}',
  'QTY AUTHORIZE': 'QTY AUTHORIZE ${index}',
  'DOCTOR ID': 'DOCTOR ID {$index}',
  'DOCTOR PHONE': 'DOCTOR PHONE ${index}',
  'DOCTOR FAX': 'DOCTOR FAX ${index}',
  'RX NUMBER': 'RX NUMBER ${index}',
  'RX DATE': 'RX DATE ${index}',
  WB_Date: 'WB DATE ${index}',
  STATUS: 'STATUS ${index}',
  'QTY DISPENSE': 'QTY DISPENSE ${index}',
  rphQuantity: 'rphQuantity ${index}',
  rphRepeat: 'rphRepeat ${index}',
}
