// import medicationServerInterface from "../../common/MedicationForm/interface"
import medicationServerInterface from '@/components/common/MedicationForm/interface'

export default function (medications, state, callback) {
  let index = 1

  medications.forEach(med => {
    // if it is discountinued medicication
    if (med['DISCONTINUED MEDS']) {
      return
    }
    if (callback) {
      Object.keys(med).forEach(callback)
    } else {
      Object.keys(med).forEach(key => {
        if (typeof medicationServerInterface[key] === 'string') {
          const propertyWithIndex = medicationServerInterface[key].replace('${index}', index)

          // this.patientProfile[propertyWithIndex] = med[key];
          state[propertyWithIndex] = med[key]
        } else {
          console.error('Missing in the interface', key)
        }
      })
    }

    // another med to go in the table
    index++
  })

  state['TOTAL MEDS'] = --index // removing the extra one

  return state
}
